<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="promotionDetails?.products"
      :loading="false"
      :items-per-page="10"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
      show-expand
      :expanded.sync="expanded"
      :single-expand="singleExpand"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.product.images && item.product.images[0]?.url"
          :src="item.product.images[0]?.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.stock="{ item }">
        <span>
          {{ item.lots.reduce((sum, lot) => sum + lot.qte_in_lot, 0) }}
        </span>
      </template>
      <template v-slot:item.discount_price="{ item }">
        <span>
          {{
            (() => {
              let discountPrice =
                (item.product.price_ht * promotion.discount) / 100;
              if (discountPrice >= parseFloat(promotion.max_discount_amount)) {
                return Number(promotion.max_discount_amount).toFixed(2);
              }
              return Number(discountPrice).toFixed(2);
            })()
          }}
        </span>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="headers.length"
          class="py-2 deep-purple lighten-5"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Batch N°</th>
                  <th class="text-left">{{ $t("quantity") }}</th>
                  <th class="text-left">Expiration Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="lot in item.lots"
                  :key="lot.id"
                >
                  <td>{{ lot.lot_number }}</td>
                  <td>{{ lot.qte_in_lot }}</td>
                  <td>{{ lot.expiration_date }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      hubs: "hubs/activeHubs",
      promotionDetails: "promotions/promotionDetails",
      promotion: "promotions/promotion",
      productsList: "promotions/productsList",
    }),
    console: () => window.console,
  },
  data() {
    return {
      is_loading_hubs: false,
      options: {},
      expanded: [],
      index: 0,
      is_loading: false,
      is_loading_invoice: false,
      is_loading_dn: false,
      singleExpand: false,

      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "",
          align: "start",
          sortable: true,
          value: "image",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "product.name",
        },

        {
          text: this.$t("stock"),
          align: "start",
          sortable: false,
          value: "stock",
        },
        {
          text: this.$t("price"),
          align: "start",
          sortable: false,
          value: "product.price",
        },

        {
          text: this.$t("discount_price"),
          value: "discount_price",
          align: "start",
          sortable: true,
        },
      ],
    };
  },

  methods: {},
};
</script>
